import React from "react"

import { Layout } from "../components"


export default function NotFoundPage() {
	return (
		<Layout title="404: Not Found">
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Layout>
	)
}
